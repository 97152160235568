<div class="jumbo-page">
  <div class="page-header header-filter" data-parallax="true" style="background-image: url('assets/img/bg2.jpg');">
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto">
          <div class="brand">
            <h1>Fitz Of Code</h1>
            <h3>Tutorial based blogs and videos on everything development.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container bg-light rounded" style="position: relative; top:-20px;">
    <div class="row">
      <h2>Welcome to the home page</h2>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>
  </div>
</div>
<!-- <div class="containter">
      <h2>Welcome to the home page</h2>
  </div> -->